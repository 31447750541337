import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    loadChildren: () => import('./modules/cocktail/cocktail.module').then(m => m.CocktailModule)
  },
  {
    loadChildren: () => import('./modules/product-card-slider/product-card-slider.module').then(m => m.ProductCardSliderModule)
  }
];
