export const modules = [
  {
    name: 'Cocktail',
    module: () => import('./cocktail/cocktail.module').then(m => m.CocktailModule)
  },
  {
    name: 'ProductCardSlider',
    module: () => import('./product-card-slider/product-card-slider.module').then(m => m.ProductCardSliderModule)
  }
];
